.accept-button {
  background-color: #8ec564;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  padding: 10px 25px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0.25rem;
  border: 2px solid #8ec564;
  min-width: 150px;
  width: 100%;
}

.accept-button:focus {
  outline: none;
}
.accept-button:hover {
  background-color: darken(#8ec564, 5%);
  color: #fff;
}
.accept-button:active {
  transform: translateY(1px);
}
.accept-button:disabled {
  background-color: white;
  color: #8ec564;
  cursor: not-allowed;
  border: 3px solid #8ec564;
  font-weight: 600;
}
.accept-button:hover:disabled {
  background-color: darken(white, 5%);
}

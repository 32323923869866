.hire-blocked {
  background-color: lighten(gray, 35%);
  color: $white;
  cursor: not-allowed;
}
.hire-blocked {
  background-color: lighten(gray, 33%);
}

.hire-open {
  background-color: #8ec564;
  cursor: pointer;
}
.hire-open:hover {
  background-color: darken(#8ec564, 5%);
  color: #fff;
}
.hire-open:active {
  transform: translateY(1px);
}

.hire-open-reject {
  background-color: #df676b;
  cursor: pointer;
}
.hire-open-reject:hover {
  background-color: darken(#df676b, 5%);
  color: #fff;
}
.hire-open-reject:active {
  transform: translateY(1px);
}

.hire-offered {
  background-color: #8ec564;
  cursor: not-allowed;
}

.hire-rejected {
  background-color: #df676b;
  cursor: not-allowed;
}
.hire-rejected:hover {
  background-color: darken(#df676b, 5%);
  color: #fff;
}

.hire-done {
  background-color: #8ec564;
  cursor: not-allowed;
  border: 1px solid darken(#8ec564, 40%) !important;
}

.hire-button-cell {
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 12px;
  line-height: 20px;
  padding: 5px 10px;
  text-transform: uppercase;
  width: fit-content;
  border-radius: 0.25rem;
  border: none;
}

.hire-button-cell:focus {
  outline: none;
}

.hire-reject-buttons {
  display: flex;
  flex-direction: row;
  font-size: 20px;
  width: 100%;

  > button:first-of-type {
    margin-right: 5px;
  }

  > button:last-of-type {
    margin-left: 5px;
  }
}

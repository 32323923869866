@import './assets/scss/Saas.scss';

// colors
$primaryColor1: #074153;
$primaryColor2: #ff0062;
$primaryColor3: #000000;

$secondaryColor1: #75009a;
$secondaryColor2: #ff6394;
$secondaryColor3: #f6f6f6;

$white: #ffffff;
$grey: #888;
$darkgrey: #444444;

// responsive breakpoints
$break-large: 1300px;

// Layout and Grid
$max-width-layout: 1600px;
$min-width-sidenav: 250px;

@media print {
  .noprint {
    display: none !important;
    visibility: hidden !important;
  }
  .footer {
    min-height: 60px !important;
    max-height: 60px !important;
    height: 60px !important;
  }
  .header {
    position: unset !important;
  }
  .DashboardLayout .max-width {
    max-width: unset !important;
  }
}

.notification-point {
  display: inline-block;
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  height: 7px;
  width: 7px;
  background-color: $danger;
}

.filters-info div:nth-of-type(1n + 9) {
  display: none;
}

// Pages
@import './pages/CompanyDetails/scss/CompanyDetails.scss';
@import './pages/SeafarerDetails/scss/SeafarerDetails.scss';
@import './pages/SeafarerVerification/scss/SeafarerVerification.scss';
@import './pages/SeafarerCV/scss/SeafarerCV.scss';
@import './pages/SeafarerQuickViewProfile/SeafarerQuickViewProfile.scss';

// Components
@import './components/scss/index.scss';
@import './components/Profile/scss/index.scss';
@import './components/Table/scss/index.scss';

// Form Input
@import './components/Form/scss/index.scss';

.table-tooltip {
  height: 24px;
  display: flex;
  opacity: 0;
  background-color: lighten(grey, 40%);
  border-radius: 0.175rem;
  font-size: 12px;
  font-weight: 500;
  width: 100%;
  padding: 4px 10px;
}

.tooltip-active {
  opacity: 1;
  transition: opacity 0.5s;
}

.category-title {
  display: flex;
  font-size: 20px;
  font-weight: 400;
}

.category-form {
  display: flex;
  flex-direction: column;
  background-color: white !important;
  padding-top: 10px;

  > .sub-profile-row {
    display: flex;
    flex-direction: row;
    overflow-x: auto;
    -webkit-transform: translate3d(0, 0, 0);

    > .form-property {
      display: flex;
    }
  }
}

.accordion__button {
  width: hidden !important;
  word-wrap: break-word;
  min-height: 1px;
  box-sizing: border-box;
  background-color: white !important;
}

.accordion {
  border: none !important;
}

__panel {
  padding: 0 !important;
}

.accordion__item {
  min-width: 200px;
  max-width: calc(#{$max-width-layout} - 45px);
}

.CompanyLikesCard {
  display: flex;
  flex-direction: column;
  min-height: 200px;
  .CompanyLikesCard-item {
    display: flex;
    flex-direction: row;
    column-gap: 20px;
    align-items: center;
    justify-content: space-between;
    min-height: 40px;
    .CompanyLikesCard-item-name {
      font-size: 14px;
      font-weight: 500;
      color: #4a4a4a;
      min-width: 180px;
    }
    &:hover {
      background: rgba(250, 250, 245, 0.5);
      background-color: rgba(250, 250, 245, 0.5);
    }
  }
}

.ExclusionCell {
  display: flex;
  margin-left: 30px;
  width: 100%;
  > img {
    height: 20px;
    width: 20px;
    cursor: pointer;
  }
}

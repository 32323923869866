.company-header-container {
  display: flex;
  flex-direction: row;
  position: relative;

  > .menu-validated {
    position: absolute;
    top: 0;
    right: 0;
    background-color: forestgreen;
    font-size: 10px;
    color: white;
    font-weight: 700;
    padding: 2px 5px;
    border-radius: 0.125rem;
    width: fit-content;
    align-self: center;
    letter-spacing: 1px;
  }

  > .header-left {
    display: flex;
    flex-direction: row;
    width: 6rem;
    height: 6rem;

    > .header-logo {
      position: relative;
      background: #fff;
      width: max-content;
      max-width: 200px;
      height: max-content;
      max-height: 150px;
      border: 5px #dcdee1 solid;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;

      > img {
        width: 100%;
      }
    }
  }
}

.ProfileValue {
  display: flex;
  flex-direction: column;
  width: -webkit-fill-available;
  margin: 0px 10px 20px 10px;

  > div {
    border: 0;
    line-height: 20px;
    box-sizing: border-box;
    color: $primaryColor1;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.0625rem;
    height: auto;
    width: max-content;
    min-width: 190px;
  }
}

.LabelTypeValue {
  display: flex;
  line-height: 18px;
  color: #666;
  font-size: 1rem;
  font-weight: 600;
  -webkit-font-smoothing: antialiased;
  letter-spacing: 0.0625rem;
  margin-top: 20px;
  margin-bottom: 10px;
}

.ImageTypeValue {
  margin-bottom: 20px;
  img {
    max-width: 200px;
    width: 200px;
  }
}

.DateRange {
  display: flex;
  flex-direction: column;
  .date-pickers {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    column-gap: 5px;
    justify-content: start;
    align-items: center;
    .DateTimeInput {
      min-width: 190px;
    }
  }
}

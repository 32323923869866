.Pagination {
  display: flex;
  justify-content: flex-end;
  white-space: nowrap;

  > div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    row-gap: 10px;
    > .page-number {
      font-size: 16px;
      font-weight: 400;
      margin: 0px 5px 0 5px;
      align-self: center;
    }
    > button {
      height: 30px;
      width: 30px;
      margin: 0px 5px 0 5px;
    }

    > select {
      height: 30px;
      margin: 0px 0px 0 5px;
      font-size: 16px;
      width: 120px;
    }
  }
}

.SearchInput {
  display: flex;
  flex-direction: row;
  margin-right: 20px;
  width: 100%;

  > input {
    border: 2px solid #e1e8ee;
    border-radius: 0.25rem;
    line-height: 36px;
    box-sizing: border-box;
    padding: 0;
    color: $primaryColor1;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.0625rem;
    padding-left: 5px;
    padding-right: 5px;
    width: 100%;
  }

  active,
  input:focus {
    outline: none;
    border: 2px solid $primaryColor1;
    border-radius: 0.25rem;
  }

  > input::placeholder {
    color: $primaryColor1;
    opacity: 0.6;
    text-align: left;
    font-size: 0.875rem;
    font-weight: 400;
    line-height: 36px;
  }
}

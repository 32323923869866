$checkbox-width: 18px;

.AuthenticationTandC {
  > .authentication-tandc {
    position: relative;
    display: block;

    > label {
      line-height: 18px;
      color: black;
      font-size: 0.875rem;
      font-weight: 400;
      -webkit-font-smoothing: antialiased;
      letter-spacing: 0.0625rem;
      user-select: none;
      text-align: left;
    }

    > input[type='checkbox'] {
      position: absolute;
      display: none;

      & + label {
        position: relative;
        display: inline-block;
        padding: 10px 15px 10px ($checkbox-width + 10);

        // Default State
        &:before {
          display: block;
          content: '';
          position: absolute;
          left: 0;
          width: $checkbox-width;
          height: $checkbox-width;
          border: 1px solid #ddd;
          cursor: pointer;
        }
      }

      // Checked State
      &:checked + label {
        &:before {
          background: $primaryColor1;
        }
      }
    }
  }
}

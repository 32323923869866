.AuthenticationSelect {
  display: flex;
  flex-direction: column;
  width: 100%;

  > select {
    border: 0;
    border-bottom: 1px solid darken(#ddd, 20%);
    height: 40px;
    box-sizing: border-box;
    padding: 0;
    color: $primaryColor1;
    font-size: 0.875rem;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
    letter-spacing: 0.0625rem;
  }

  > select:required:invalid {
    color: $darkgrey;
    opacity: 0.5;
    text-align: left;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: 0.0625rem;
  }

  select:focus {
    outline: none;
    border-bottom: 1px solid darken($primaryColor1, 30%);
  }

  > i {
    color: $primaryColor2;
    display: inline-block;
    font-size: 10px;
    line-height: 20px;
    height: 20px;
    font-style: normal;
    text-align: left;
  }
}

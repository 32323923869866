.ProgressBar {
  height: 30px;
  width: 100%;
  background-color: #e0e0de;
  border-radius: 2px;

  > .filler-style {
    height: 100%;
    border-radius: inherit;
    transition: width 1s ease-in-out;

    > span {
      color: white;
      font-weight: bold;
      display: flex;
      align-items: center;
      justify-content: center;
      height: 100%;
      font-size: 18px;
      font-weight: 500;
    }
  }
}

.vertical-centered-box {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  > img {
    height: fit-content;
    display: flex;
    position: relative;
    left: -30px;
  }
}

.loader-line-mask {
  position: relative;
  width: 50px;
  height: 100px;
  overflow: hidden;
  -webkit-transform-origin: 50px 50px;
  -moz-transform-origin: 50px 50px;
  -ms-transform-origin: 50px 50px;
  -o-transform-origin: 50px 50px;
  transform-origin: 50px 50px;
  -webkit-mask-image: -webkit-linear-gradient(top, #000000, rgba(0, 0, 0, 0));
  -webkit-animation: rotate 1.6s infinite linear;
  -moz-animation: rotate 1.6s infinite linear;
  -o-animation: rotate 1.6s infinite linear;
  animation: rotate 1.6s infinite linear;
}
.loader-line-mask .loader-line {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  box-shadow: inset 0 0 0 3px rgba($primaryColor1, 0.5);
}

@-webkit-keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
  }
}
@-moz-keyframes rotate {
  0% {
    -moz-transform: rotate(0deg);
  }
  100% {
    -moz-transform: rotate(360deg);
  }
}
@-o-keyframes rotate {
  0% {
    -o-transform: rotate(0deg);
  }
  100% {
    -o-transform: rotate(360deg);
  }
}
@keyframes rotate {
  0% {
    -webkit-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  100% {
    -webkit-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -ms-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
[not-existing] {
  zoom: 1;
}

@-webkit-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-moz-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@-o-keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
@keyframes fade {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.25;
  }
}
[not-existing] {
  zoom: 1;
}

@-webkit-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-moz-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@-o-keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
@keyframes fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
[not-existing] {
  zoom: 1;
}

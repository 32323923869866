.seafarer-profile-details-container {
  display: flex;
  flex-direction: row;
  position: relative;

  > .menu-validated {
    position: absolute;
    top: 0;
    right: 0;
    background-color: forestgreen;
    font-size: 10px;
    color: white;
    font-weight: 700;
    padding: 2px 5px;
    border-radius: 0.125rem;
    width: fit-content;
    align-self: center;
    letter-spacing: 1px;
  }

  > .header-left {
    display: flex;
    flex-direction: row;
    width: 100%;

    > .header-logo {
      position: relative;
      background: #fff;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 5px #dcdee1 solid;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1px;
      padding-bottom: 1px;
      > img {
        width: 100%;
        border-radius: 50%;
      }
    }

    > .header-user-logo {
      border: 4px solid white;
      border-radius: 50%;
      height: 140px;
      width: 140px;
      align-self: center;
      background: #ddd;
      line-height: 136px;
      text-align: center;
      font-size: 40px;
      box-sizing: border-box;
      text-transform: uppercase;
    }
  }

  > .header-right {
    display: flex;
    flex-direction: column;
    width: 80%;
    justify-content: flex-start;
    row-gap: 20px;

    .header-details-row {
      display: flex;
      flex-direction: row;
      justify-content: start;
    }

    .header-details-column {
      display: flex;
      flex-direction: column;
      flex: 1;
      justify-content: flex-end;

      > div:not(:last-child) {
        margin-bottom: 15px;
      }
    }

    > div:first-child {
      flex: 0.7;
    }
  }
}

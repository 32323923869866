.reset-button {
  /*background-color: #df676b;*/
  color: #000;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 14px;
  line-height: 16px;
  cursor: pointer;
  padding: 15px 5px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0.25rem;
  border: none;
  width: 100%;
}

.reset-button:focus {
  outline: none;
}
.reset-button:hover {
  /* background-color: darken(#df676b, 5%); */
  color: #000;
}
.reset-button:active {
  transform: translateY(1px);
}
.reset-button:disabled {
  /*background-color: lighten(gray, 35%);*/
  color: $white;
  cursor: not-allowed;
}

/*.reset-button:hover:disabled {
  background-color: lighten(gray, 33%);
}
*/

.cancel-button {
  background-color: #df676b;
  color: #fff;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 12px;
  line-height: 14px;
  cursor: pointer;
  padding: 10px 5px;
  text-transform: uppercase;
  width: 100%;
  border-radius: 0.25rem;
  border: 2px solid #df676b;
  min-width: 150px;
  width: 100%;
  &.fit-content {
    width: fit-content;
  }
}

.cancel-button:focus {
  outline: none;
}
.cancel-button:hover {
  background-color: darken(#df676b, 5%);
  color: #fff;
}
.cancel-button:active {
  transform: translateY(1px);
}
.cancel-button:disabled {
  background-color: white;
  color: #df676b;
  cursor: not-allowed;
  border: 3px solid #df676b;
  font-weight: 600;
}
.cancel-button:hover:disabled {
  background-color: lighten(white, 5%);
}

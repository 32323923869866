.SeafarerVerification {
  display: flex;
  flex-direction: column;
  row-gap: 20px;
  margin: 20px;
  max-width: $max-width-layout;
  min-width: 250px;
  width: -webkit-fill-available;
  overflow: auto;

  > form {
    width: 100%;
    > .profile-details {
      display: flex;
      flex-direction: column;
      margin-bottom: 10px;
      width: 100%;
    }
  }
}

@import '../components/scss/index.scss';

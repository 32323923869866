.ConfirmationModal {
  margin: 10px;
  display: flex;
  flex-direction: column;
  max-width: 350px;

  > .confirmation-modal-buttons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    column-gap: 20px;
    row-gap: 10px;
    .button-container {
      max-width: 150px;
    }
  }
  > .confirmation-modal-text {
    font-size: 16px;
    margin-bottom: 30px;
  }
}

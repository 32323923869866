.TrafficLightCell {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  > div {
    border-radius: 50%;
    height: 20px;
    width: 20px;
  }

  > .yellow-traffic-icon {
    background: #ffbc00;
  }

  > .green-traffic-icon {
    background: #95c11f;
  }

  > .red-traffic-icon {
    background: #f20a41;
  }

  > .white-traffic-icon {
    background: #fff;
  }
}

.seafarer-profile-details-container {
  display: flex;
  flex-direction: row;
  position: relative;

  > .header-left {
    display: flex;
    flex-direction: row;
    width: 100%;

    > .header-logo {
      position: relative;
      background: #fff;
      width: 100px;
      height: 100px;
      border-radius: 50%;
      border: 5px #dcdee1 solid;
      overflow: hidden;
      display: flex;
      justify-content: center;
      align-items: center;
      padding-left: 1px;
      padding-bottom: 1px;
      > img {
        width: 100%;
        border-radius: 50%;
      }
    }

    > .header-user-logo {
      border: 4px solid white;
      border-radius: 50%;
      height: 140px;
      width: 140px;
      align-self: center;
      background: #ddd;
      line-height: 136px;
      text-align: center;
      font-size: 40px;
      box-sizing: border-box;
      text-transform: uppercase;
    }
  }
}

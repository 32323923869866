.ProfileStrength {
  .dashboard-title {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    margin-bottom: 20px;
    > div:first-of-type {
      font-size: 18px;
      color: #5e6977;
      font-weight: 500;
    }
    > div:nth-child(2) {
      line-height: 20px;
      color: black;
      font-size: 1.2rem;
      font-weight: 600;
      -webkit-font-smoothing: antialiased;
    }
  }
}

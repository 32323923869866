.TableBooleanInput {
  display: flex;
  flex-direction: row;
  font-size: 20px;

  > button:first-child {
    margin-right: 5px;
  }

  > button:last-child {
    margin-left: 5px;
  }
  > button {
    height: 30px;
    width: 30px;
    background: none;
    display: flex;
    justify-content: center;
    align-items: center;
    border: none;

    > .active-selection {
      filter: opacity(20%);
    }

    > img {
      height: 20px;
      width: 20px;
    }
  }

  > .interview-accepted-button {
    border: 3px solid rgb(174, 160, 135);
    border-radius: 50%;
  }
}

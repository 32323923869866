.form-table-view {
  overflow-x: auto;
  -webkit-transform: translate3d(0, 0, 0);
  width: inherit;

  > table {
    > thead {
      > tr {
        > th {
          min-width: 100px;
          max-width: 400px;
          line-height: 16px;
          color: black;
          font-size: 0.7rem;
          font-weight: 600;
          -webkit-font-smoothing: antialiased;
          letter-spacing: 0.0625rem;
          padding: 0 10px 0 5px;
          text-align: left;
        }
      }
    }

    > tbody {
      > tr {
        > img {
          height: 30px;
        }

        > td {
          min-width: 50px;
          max-width: 400px;
          vertical-align: top;

          > div {
            margin: 0;
          }
        }
      }
    }
  }
}

.button-div {
  height: 40px;
  width: 40px;
  display: flex;
  justify-content: center;
  align-items: center;

  > img {
    height: 20px;
  }

  > .table-add-row {
    margin-left: 4px;
  }

  > img:focus {
    outline: none;
  }

  > img:active {
    transform: translateY(1px);
  }
}

.ProfileVerificationCard {
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
  width: 100%;
  .category-form {
    display: flex;
    flex-direction: column;
    background-color: white !important;
    padding-top: 10px;
    > .sub-category-row {
      display: flex;
      flex-direction: row;
      > .sub-profile-row {
        display: flex;
        flex-direction: row;
        overflow-x: auto;
        -webkit-transform: translate3d(0, 0, 0);
        column-gap: 20px;
      }
    }
  }
}

.AuthenticationSubmitButton {
  background-color: $primaryColor1;
  color: $white;
  border: none;
  font-weight: 500;
  letter-spacing: 0.0625rem;
  font-size: 0.6875rem;
  line-height: 20px;
  cursor: pointer;
  padding: 15px 32px;
  text-transform: uppercase;
  width: fit-content;
}

.AuthenticationSubmitButton:focus {
  outline: none;
}
.AuthenticationSubmitButton:hover {
  background-color: darken($primaryColor1, 5%);
}
.AuthenticationSubmitButton:active {
  transform: translateY(1px);
}
.AuthenticationSubmitButton:disabled {
  background-color: $secondaryColor3;
  color: $grey;
  cursor: not-allowed;
}
.AuthenticationSubmitButton:hover:disabled {
  background-color: darken($secondaryColor3, 10%);
}

.FormFooter {
  display: flex;
  flex-direction: row;
  width: -webkit-fill-available;
  align-self: center;
  padding: 20px 0px;

  > .form-buttons {
    display: flex;
    flex-direction: column;
    width: max-content;
    align-self: center;
    flex: 1;
    padding: 0 20px 0 10px;
  }
}

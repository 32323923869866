.remove-seafarer-modal {
  margin-top: 20px;
  display: flex;
  flex-direction: column;

  > .description {
    margin-bottom: 35px;

    ul > li {
      margin-bottom: 10px;
    }
  }

  > .delete-seafarer-buttons {
    display: flex;
    flex-direction: column;
    width: max-content;
    align-self: center;

    > .third {
      display: flex;
      flex-direction: row;
      margin-bottom: 10px;
      width: 100%;
    }

    > .fourth {
      display: flex;
      flex-direction: row;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

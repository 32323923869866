.FilterButtonsRow {
  display: flex;
  flex-direction: row;
  column-gap: 5px;
  row-gap: 10px;
  flex-wrap: wrap;
  .filter-button {
    background-color: #fff;
    color: $primaryColor1;
    font-weight: 500;
    letter-spacing: 0.0625rem;
    font-size: 12px;
    line-height: 14px;
    cursor: pointer;
    padding: 10px 5px;
    text-transform: uppercase;
    border-radius: 0.25rem;
    border: 2px solid $primaryColor1;
    min-width: 190px;
    &:focus {
      outline: none;
    }
    &:active {
      transform: translateY(1px);
    }
    &:disabled {
      background-color: lighten(gray, 35%);
      color: $white;
      cursor: not-allowed;
    }
    &:hover:disabled {
      background-color: lighten(gray, 33%);
    }
    &.selected-filter-button,
    &:hover {
      background-color: darken($primaryColor1, 5%);
      color: #fff;
    }
  }
}
